<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  filter: {
    type: Object,
    required: true
  },
  filtersStore: {
    type: Object,
    required: true
  },
  useSlug: {
    type: Boolean,
    default: false
  }
});

const value = ref(undefined);
const value_string = ref(undefined);
const selected_option = ref({
  name: value_string.value,
  url: value.value,
  top_parent: '',
});
const currentLocation = computed(() => props.filtersStore.currentLocation);

const cities=ref([])
const search = async (q='') => {
 
  let url = `/api/data/locations?term=${q}`;
  if (props.filtersStore?.customQuery?.location) {
    const queryString = Object.entries(props.filtersStore.customQuery.location)
      .map(([key, value]) => `${key}=${value}`).join('&');
    url += `&${queryString}`;
  }

  cities.value = await $fetch(url);

  return cities;
}
search('') // to get the cities

const onSelect = (val) => {
  value.value = props.useSlug ? val.slug : (val.url || 'turkey');
  value_string.value = val.name || 'Turkey';
  props.filtersStore.updateValue(props.filter, {
    label_value: value_string.value,
    slug: value.value,
  });

  props.filtersStore.updateDevelopers(val._id);
}
</script>

<template>
  <div class="flex flex-col">
    <span v-if="filtersStore?.showInputLabel" class="text-neutral-400 text-xs font-normal mb-2 ms-1">
      {{ filter.name }}
    </span>
    <w-input
      :select="true"
      :options="cities" 
      v-model:value="selected_option" 
      :placeholder="currentLocation || selected_option.name || 'Enter Location'" option-attribute="name" trailing
      type="select" 
      name="city"
      trackBy="_id"
      label="name"
      class="mb-3 me-2"
      @change="onSelect" 
     />
    
  </div>
</template>
